import * as React from "react";
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";
import NotFoundPage from "./404";

const allowPreview = process.env.GATSBY_ALLOW_PREVIEW == "true";

const PreviewPage = () => {
  return (
    <div>
      <h1>Loading preview…</h1>
    </div>
  );
};

export default allowPreview
  ? withPrismicPreviewResolver(PreviewPage)
  : NotFoundPage;
